<template>
    <el-card class="box-card">
        <div class="search-table-box">
            <div>
                <el-form ref="form" :inline="true" :model="queryParams" label-width="70px">
                    <el-form-item label="状态" label-width="50px">
                        <el-select style="width: 270px" v-model="queryParams.cancelState" placeholder="请选择状态" clearable>
                            <el-option v-for="item in organizationAttributeCategoryOption" :label="item.label" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属区划">
                        <el-cascader
                            style="width: 270px"
                            :props="props"
                            placeholder="请选择所属区划"
                            v-model="queryParams.administrativeDivision"
                            @change="change"
                            clearable
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="补贴等级">
                        <el-select style="width: 270px" v-model="queryParams.subsidyLevelId" placeholder="请选择补贴等级" clearable>
                            <el-option v-for="item in levelConfig" :label="item.levelName" :value="item.id" :key="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="关键字" label-width="60px">
                        <el-input style="width: 270px" clearable v-model="queryParams.keyWord" placeholder="请输入关键字"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" class="search" @click="searchList">查询</el-button>
                        <el-button type="primary" class="search" plain @click="searchList">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="btn">
                    <el-button type="primary" @click="templateDown">模板下载</el-button>
                    <el-button type="primary" plain @click="uploadVisible = true">导入</el-button>
                    <el-button type="primary" @click="downTemp">导出</el-button>
                </div>
            </div>
            <el-table v-loading="loading" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
                <template slot="empty">
                    <IsEmpty />
                </template>
                <el-table-column type="index" label="序号" :index="count" align="center" width="80" />
                <el-table-column prop="userName" align="center" label="姓名" show-overflow-tooltip />
                <el-table-column prop="age" align="center" label="年龄" width="160" />
                <el-table-column prop="creatTime" align="center" label="性别" width="160">
                    <template slot-scope="scope">
                        <span>{{ ['未知', '男', '女'][scope.row.sex * 1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userPhone" align="center" label="联系电话" />
                <el-table-column prop="streetName" align="center" label="所属区划" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.streetName }}</span>
                        <span v-if="scope.row.communityName">/{{ scope.row.communityName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="residenceAddress" align="center" label="户籍地址" show-overflow-tooltip />
                <el-table-column prop="subsidyType" align="center" label="身份类别">
                    <template slot-scope="scope">
                        <span>{{ ['普通老人', '低保老人', '失能老年人', '低保失能老年人'][scope.row.subsidyType * 1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="subsidyLevel" align="center" label="补贴等级">
                    <template slot-scope="scope">
                        <span>{{ scope.row.subsidyLevel }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="subsidyStandard" align="center" label="月标准(元)" />
                <el-table-column prop="cancelState" align="center" label="状态">
                    <template v-slot="scope">
                        <span>{{ ['未停发', '停发审批中', '已停发'][scope.row.cancelState * 1] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" min-width="120">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="queryParams.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total"
            >
            </el-pagination>
            <!-- 上传 -->
            <UploadFile type="elderly" url="/advancedAgeSubsidy/elderly/template/import" :uploadVisible.sync="uploadVisible" @updateList="getList" />
        </div>
    </el-card>
</template>

<script>
import { administrativeDivision } from '@/api/institutionalManage'
import { downloadStream } from '@/utils'
import UploadFile from '@/components/uploadFile'
import { getElderList, exportElder, getPensionSubsidyLevelNameList, templateexport, templateimport } from '@/api/highCollarSubsidy'
export default {
    name: 'index',
    components: {
        UploadFile
    },
    data() {
        return {
            areaList: [], // 行政区域
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad: this.lazyLoad
            },
            tableData: [],
            signStatus: '',
            total: 0,
            loading: false,
            levelConfig: [],
            organizationAttributeCategoryOption: [
                { label: '未停发', id: 0 },
                { label: '停发审批中', id: 1 },
                { label: '已停发', id: 2 }
            ],
            queryParams: {
                //查询参数
                pageNum: 1,
                pageSize: 10,
                tenantId: '', //街道ID
                orgId: '', //社区ID
                cancelState: '', //状态
                subsidyLevelId: '', //补贴等级
                administrativeDivision: '', // 行政区划
                keyWord: '' // 关键字
            },
            uploadVisible: false
        }
    },
    mounted() {
        this.getList()
        this.getPensionSubsidyLevelNameListData()
    },
    methods: {
        async getPensionSubsidyLevelNameListData() {
            const res = await getPensionSubsidyLevelNameList()
            this.levelConfig = res.data
        },
        // 获取行政区划
        async lazyLoad(node, resolve) {
            const { data } = node
            let res
            if (data) {
                res = await administrativeDivision({ organizationId: data.id })
            } else {
                res = await administrativeDivision()
            }
            const nodes = res.data.map(item => ({
                value: item.organizationId,
                label: item.organizationName,
                id: item.organizationId
            }))
            resolve(nodes)
        },
        change(value, item) {
            console.log(value, item, 'change')
            if (value.length === 2) {
                this.queryParams.tenantId = value[value.length - 1]
            } else if (value.length === 3) {
                this.queryParams.tenantId = value[value.length - 2]
                this.queryParams.orgId = value[value.length - 1]
            } else {
                this.queryParams.tenantId = ''
                this.queryParams.orgId = ''
            }
        },
        // 下载模板
        async downTemp() {
            let params = JSON.parse(JSON.stringify(this.queryParams))
            Reflect.deleteProperty(params, 'administrativeDivision')
            const res = await exportElder(params)
            downloadStream(res, '补贴老人资料.xlsx')
        },
        async templateDown() {
            const res = await templateexport()
            downloadStream(res, '补贴老人资料模板.xlsx')
        },
        toDetail(row) {
            this.$router.push({ path: '/viewSubsidizedElderlyInformation', query: { rowItem: JSON.stringify(row) } })
        },
        searchList() {
            this.queryParams.pageNum = 1
            this.getList()
        },
        handleSizeChange(pageSize) {
            this.queryParams.pageSize = pageSize
            this.queryParams.pageNum = 1
            this.getList()
        },
        handleCurrentChange(pageNum) {
            this.queryParams.pageNum = pageNum
            this.getList()
        },
        async getList() {
            this.loading = true
            let params = JSON.parse(JSON.stringify(this.queryParams))
            Reflect.deleteProperty(params, 'administrativeDivision')
            try {
                const res = await getElderList(params)
                this.tableData = res.data.records
                this.total = res.data.total
                this.loading = false
            } catch (e) {
                this.loading = false
            }
        },
        handlerResetList() {
            this.queryParams = {
                pageNum: 1,
                pageSize: 10,
                tenantId: '', //街道ID
                orgId: '', //社区ID
                cancelState: '', //状态
                subsidyLevelId: '', //补贴等级
                administrativeDivision: '', // 行政区划
                keyWord: '' // 关键字
            }
            this.getList()
        },
        //分页序号
        count(index) {
            return (this.queryParams.pageNum - 1) * this.queryParams.pageSize + index + 1
        }
    }
}
</script>

<style lang="scss" scoped>
.box-card {
    min-height: calc(100% - 10px);
    .btn {
        text-align: right;
        margin-bottom: 20px;
    }
    .search {
        margin-left: 10px;
    }
}
</style>
